import { Fragment } from 'react'
import { Heading, Link } from '@components/common'
import { PageTemplate } from '@components/templates'
import { ContentStylesOverride, LinksWrapper } from './_styles'
import NavigationBar from '@components/NavigationBar'

const Home = () => {
	return (
		<Fragment>
			<PageTemplate
				navigation={<NavigationBar noBackground />}
				disableNavigationPadding
				disableMaxWidth
				contentStylesOverride={ContentStylesOverride}
				background={'/img/home-background.jpg'}
			>
				<Heading.H1
					fontWeight={400}
					textTransform={'uppercase'}
					fontSize={[20, 24, 30]}
					letterSpacing={['0.2em', '0.5em']}
					color={'white'}
				>
					Welcome to Rustoria™
				</Heading.H1>
				<Heading.H2
					fontWeight={400}
					textTransform={'uppercase'}
					fontSize={[12, 16]}
					letterSpacing={'0.3em'}
					color={'white'}
				>
					<em>The Trend Setters of Modded Rust</em>
				</Heading.H2>
				<LinksWrapper>
					<Link
						backgroundColor={'bgPrimary'}
						hoverBackgroundColor={'bgQuaternary'}
						textDecoration={'none'}
						py={'8px'}
						px={37}
						borderRadius={2}
						fontSize={12}
						letterSpacing={2}
						href={'https://donate.rustoria.co/'}
					>
						STORE
					</Link>
					<Link
						backgroundColor={'bgPrimary'}
						hoverBackgroundColor={'bgQuaternary'}
						textDecoration={'none'}
						py={'8px'}
						px={37}
						borderRadius={2}
						fontSize={12}
						letterSpacing={2}
						href={'/link'}
					>
						LINK YOUR ACCOUNTS
					</Link>
				</LinksWrapper>
			</PageTemplate>
		</Fragment>
	)
}

export function getServerSideProps() {
	return {
		props: {
			head: {
				title: 'Home',
				description: 'Welcome to Rustoria. The trend setters of Modded Rust. We\'re also home to some of the best Official Vanilla servers.',
				ogImage: 'RustoriaBanner.png',
			}
		}
	}
}

export default Home