import styled, { css } from 'styled-components'

export const ContentStylesOverride = css`
	height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 15px;

	/* Heading and HomeText */
	& > :nth-child(-n + 2) {
		margin-bottom: 30px;
	}

	@media (min-width: 768px) {
		/* Heading and HomeText */
		& > :nth-child(-n + 2) {
			margin-bottom: 50px;
		}
	}
`

export const LinksWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	& > * {
		margin: 2px 2px;
	}
`

// Default export so next doesn't complain
const Styles = () => null
export default Styles
